import './App.css';

import React, { useState, useEffect } from "react";
import axios from "axios";

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import {Button, TextField} from '@mui/material';
import Select from '@mui/material/Select';

import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ParkIcon from '@mui/icons-material/Park';
import MapIcon from '@mui/icons-material/Map';
import RuleIcon from '@mui/icons-material/Rule';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';

import {APIProvider, Map, AdvancedMarker, InfoWindow, useAdvancedMarkerRef, Pin} from '@vis.gl/react-google-maps';

import { Routes, Route, Outlet, Link } from "react-router-dom";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Container } from '@mui/material';

const theme = createTheme({
  components: {
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 40
        }
      }
    }
  },
  palette: {
    primary: {
      light: '#757ce8',
      main: '#3f50b5',
      dark: '#002884',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff7961',
      main: '#f44336',
      dark: '#ba000d',
      contrastText: '#000',
    },
  },
});

export default function App() {
  return (
    <div className='app'>

      <ThemeProvider theme={theme}>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="map" element={<HuntMap />} />
            <Route path="rules" element={<Rules />} />
            <Route path="leaderboard" element={<Leaderboard />} />
            <Route path="secret" element={<Secret />} />
            <Route path="*" element={<NoMatch />} />
          </Route>
        </Routes>
      </ThemeProvider>
    </div>
  );
}

function Nav() {
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };
  const DrawerList = (
    <Box sx={{ width: 200 }} role="presentation" onClick={toggleDrawer(false)}>
      <List>
        <ListItem key="home" disablePadding>
          <ListItemButton to="/" component={Link}>
            <ListItemIcon disablePadding>
              <ParkIcon />
            </ListItemIcon>
            <ListItemText primary="Home" />
          </ListItemButton>
        </ListItem>
        <ListItem key="rules" disablePadding>
          <ListItemButton to="/rules" component={Link}>
            <ListItemIcon>
              <RuleIcon />
            </ListItemIcon>
            <ListItemText primary="Rules" />
          </ListItemButton>
        </ListItem>
        <ListItem key="map" disablePadding>
          <ListItemButton to="/map" component={Link}>
            <ListItemIcon>
              <MapIcon />
            </ListItemIcon>
            <ListItemText primary="Map" />
          </ListItemButton>
        </ListItem>
        <ListItem key="leaderboard" disablePadding>
          <ListItemButton to="/leaderboard" component={Link}>
            <ListItemIcon>
              <LeaderboardIcon />
            </ListItemIcon>
            <ListItemText primary="Leaderboard" />
          </ListItemButton>
        </ListItem>
        </List>
    </Box>
  );
    
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="fixed" sx={{ top: 0 }}>
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer(true)}
          >
            <MenuIcon />
          </IconButton>
          <Typography component="div" sx={{ flexGrow: 1 }}>
            REMEMBER THE ALAMO Square Park
          </Typography>
          <Drawer open={open} onClose={toggleDrawer(false)}>
            {DrawerList}
          </Drawer>
        </Toolbar>
      </AppBar>
    </Box>
  );
}

function Layout() {
  return (
    <div>
      {/* A "layout route" is a good place to put markup you want to
          share across all the pages on your site, like navigation. */}
      <Nav />

      {/* An <Outlet> renders whatever child route is currently active,
          so you can think about this <Outlet> as a placeholder for
          the child routes we defined above. */}
      <div className="content">
        <Outlet />
      </div>
    </div>
  );
}

function Home() {
  return (
    <div className="home">
      <picture>
          <source media="(min-width:650px)" srcSet="alamo-square.jpg" />
          <img src="alamo-square-portrait.jpg" alt=""/>
      </picture>
    </div>
  );
}

function Rules() {
  return (
    <div>
      <h2 style={{"padding-top": 20}}>Setup</h2>
      <Container>
        <List sx={{ listStyleType: 'disc', pl: 4 }}>
          <ListItem sx={{ display: 'list-item' }}>Create a group text thread with all group members + Mariel</ListItem>
          <ListItem sx={{ display: 'list-item' }}>Wear your team color</ListItem>
        </List>
      </Container>
      <h2>Rules</h2>
      <Container>
        <List sx={{ listStyleType: 'disc', pl: 4 }}>
          <ListItem sx={{ display: 'list-item' }}>To receive a location’s challenge, take a selfie with your entire group at the location and send it to your group thread with Mariel. </ListItem>
          <ListItem sx={{ display: 'list-item' }}>You have 2 hours to complete any number of challenges. Challenge submissions submitted after the end of the game won’t be scored.</ListItem>
          <ListItem sx={{ display: 'list-item' }}>Use any mode of transportation you want.</ListItem>
        </List>
      </Container>
      <h2>Scoring</h2>
      <Container>
        <List sx={{ listStyleType: 'disc', pl: 4 }}>
          <ListItem sx={{ display: 'list-item' }}>+1 for completing a challenge</ListItem>
          <ListItem sx={{ display: 'list-item' }}>+1 for completing it first</ListItem>
          <ListItem sx={{ display: 'list-item' }}>+1 for completing it the best (scored at the end)</ListItem>
        </List>
      </Container>
    </div>
  );
}

function Secret() {
  const [teams, setTeams] = useState([]);
  const [challenges, setChallenges] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState('');
  const [selectedChallenge, setSelectedChallenge] = useState('');
  const [score, setScore] = useState('');

  const HOST = process.env.NODE_ENV === 'production' ? 'https://marielsbirthday.com' : 'http://localhost:8000'

  useEffect(() => {
      axios.get(HOST + '/api/teams')
          .then(response => {
              setTeams(response.data);
          })
          .catch(error => console.error('Error fetching teams:', error));

      axios.get(HOST + '/api/challenges')
          .then(response => {
              setChallenges(response.data);
          })
          .catch(error => console.error('Error fetching challenges:', error));
  }, []);

  const handleTeamChange = (event) => {
      setSelectedTeam(event.target.value);
  };

  const handleChallengeChange = (event) => {
      setSelectedChallenge(event.target.value);
  };

  const handleScoreChange = (event) => {
    setScore(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const postData = {
        team: selectedTeam,
        challenge: selectedChallenge,
        score: parseInt(score, 10)
    };

    axios.post(HOST + '/api/scores', postData)
        .then(response => {
            alert('Score submitted successfully!');
        })
        .catch(error => {
            alert('Error submitting score: ' + error.message);
        });
  };


  return (
    <div>
      <h2>Secret</h2>
      <form onSubmit={handleSubmit}>
            <FormControl fullWidth>
                <InputLabel id="team-select-label">Team</InputLabel>
                <Select
                    labelId="team-select-label"
                    id="team-select"
                    value={selectedTeam}
                    label="Team"
                    onChange={handleTeamChange}
                >
                    {teams.map((team) => (
                        <MenuItem key={team.id} value={team.id}>
                            {team.color} (Team {team.id})
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            <FormControl fullWidth style={{ marginTop: 20 }}>
                <InputLabel id="challenge-select-label">Challenge</InputLabel>
                <Select
                    labelId="challenge-select-label"
                    id="challenge-select"
                    value={selectedChallenge}
                    label="Challenge"
                    onChange={handleChallengeChange}
                >
                    {challenges.map((challenge) => (
                        <MenuItem key={challenge.id} value={challenge.id}>
                            {challenge.title}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <TextField
                label="Score"
                type="number"
                value={score}
                onChange={handleScoreChange}
                fullWidth
                style={{ marginTop: 20 }}
            />

            <Button type="submit" variant="contained" color="primary" style={{ marginTop: 20 }}>
                Submit Score
            </Button>
        </form>
    </div>
  );
}

function HuntMap() {
  const [locations, setLocations] = useState([]);

  const [challengeData, setChallengeData] = useState({});

  const HOST = process.env.NODE_ENV === 'production' ? 'https://marielsbirthday.com' : 'http://localhost:8000'

  const apiKey = process.env.NODE_ENV === 'production' ? 'AIzaSyDXUE0I7Vs7hTsW4GgvG_gwDfGwggvzYJ4' : 'AIzaSyADwxxub2sJJ6ofoEvNdDt7FPqa7us1hEU'

  useEffect(() => {
      const fetchChallenges = axios.get(HOST + '/api/challenges');
      const fetchScores = axios.get(HOST + '/api/scores');

      Promise.all([fetchChallenges, fetchScores])
          .then(([challengesResponse, scoresResponse]) => {
              setLocations(challengesResponse.data);
              const scores = scoresResponse.data;

              // Create a map of challenges to scores
              const challengeMap = {};
              scores.forEach(score => {
                  if (!challengeMap[score.challenge]) {
                      challengeMap[score.challenge] = {
                          teams: new Set(),
                          teamIds: []
                      };
                  }
                  challengeMap[score.challenge].teams.add(score.team);
                  challengeMap[score.challenge].teamIds.push(score.team);
              });

              // Convert Set to count
              for (const challenge in challengeMap) {
                  challengeMap[challenge].count = challengeMap[challenge].teams.size;
              }

              setChallengeData(challengeMap);
          })
          .catch(error => console.error('Error fetching data:', error));
  }, []);

  const CustomMarker = ({ location, scoreData }) => {

    const [markerRef, marker] = useAdvancedMarkerRef();
    const [infowindowShown, setInfowindowShown] = useState(false);

    const toggleInfoWindow = () =>
      setInfowindowShown(previousState => !previousState);
    const closeInfoWindow = () => setInfowindowShown(false);

    return (
      <div>
        <AdvancedMarker
            position={{ lat: location.lat, lng: location.lng }}
            title={location.title}
            ref={markerRef}
            onClick={toggleInfoWindow}
        >
          <Pin
              background={scoreData.count > 0 ? '#f50057' : '#1769aa'}
              borderColor={scoreData.count > 0 ? '#f50057' : '#1769aa'}
              glyph={scoreData.count.toString()}
              glyphColor={'white'}
        />
       </AdvancedMarker>
        {infowindowShown && (
          <InfoWindow anchor={marker} onCloseClick={closeInfoWindow}>
            {location.title}
          </InfoWindow>
        )}
      </div>
    );
  };


  const defaultProps = {
    center: { lat: 37.7761052, lng: -122.431011 },
    zoom: 15
  };

  return (
  <APIProvider apiKey={apiKey}>
  <Map
    style={{width: '100vw', height: '100vh'}}
    defaultCenter={defaultProps.center}
    mapId={'1811e347ac2b7f03'}
    defaultZoom={defaultProps.zoom}
    gestureHandling={'greedy'}
    disableDefaultUI={true}>

    {locations.map(location => (
      <CustomMarker
        key={location.id}
        location={location}
        scoreData={challengeData[location.id] || { count: 0, teamIds: [] }}
      />
    ))}

    </Map>
  </APIProvider>
  );
}
function Leaderboard() {
  const [teams, setTeams] = useState([]);
  const [tableData, setTableData] = useState({});
  const [scores, setScores] = useState({});
  const [challenges, setChallenges] = useState({});
  const [totals, setTotals] = useState({});

  const HOST = process.env.NODE_ENV === 'production' ? 'https://marielsbirthday.com' : 'http://localhost:8000'

  useEffect(() => {
    const fetchTeams = axios.get(HOST + '/api/teams');
    const fetchChallenges = axios.get(HOST + '/api/challenges');
    const fetchScores = axios.get(HOST + '/api/scores');

    axios.all([fetchTeams, fetchChallenges, fetchScores]).then(axios.spread((...responses) => {
      const responseTeams = responses[0].data;
      const responseChallenges = responses[1].data;
      const responseScores = responses[2].data;

      setTeams(responseTeams);
      setChallenges(responseChallenges);
      setScores(responseScores);

      let data = {};
      let colorTotals = {};
      responseTeams.forEach(team => {
        colorTotals[team.color] = 0;  // Initialize totals for each color
      });

      responseChallenges.forEach(challenge => {
        data[challenge.id] = { challengeTitle: challenge.title, scores: {} };
        responseTeams.forEach(team => {
          data[challenge.id].scores[team.color] = 0;  // Initialize scores
        });
      });

      responseScores.forEach(score => {
        const team = responseTeams.find(team => team.id === score.team);
        const challenge = responseChallenges.find(challenge => challenge.id === score.challenge);
        if (team && challenge) {
          data[challenge.id].scores[team.color] = score.score;
          colorTotals[team.color] += score.score;  // Accumulate totals for each color
        }
      });

      setTableData(data);
      setTotals(colorTotals);
    })).catch(errors => {
      console.error('Error fetching data:', errors);
    });
  }, []);

  return (
    <div>
      <h2 style={{"padding-top": 20}}>Leaderboard</h2>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              {teams.map(team => (
                <TableCell key={team.id} style={{ backgroundColor: team.color, color: '#fff' }}>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {/* Total row */}
            <TableRow>
              <TableCell component="th" scope="row"><strong>Total</strong></TableCell>
              {teams.map(team => (
                <TableCell key={team.color}><strong>{totals[team.color]}</strong></TableCell>
              ))}
            </TableRow>
            {Object.values(tableData).map((row) => (
              <TableRow key={row.challengeTitle}>
                <TableCell component="th" scope="row">
                  {row.challengeTitle}
                </TableCell>
                {teams.map(team => (
                  <TableCell key={team.color}>{row.scores[team.color]}</TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

function NoMatch() {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
}